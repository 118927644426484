<div *ngIf="(basisOverview | async) as basisOverview; else loading">
    <ng-container *ngIf="(vehicles | async) as vehicles; else loading">

	    <app-boxes [boxes]="basisOverview.boxes" class="boxes-wrapper"></app-boxes>

        <mat-card class="pt-2 pb-2" appearance="outlined" *ngIf="(fleet | async)?.rfidTrainingMode" style="text-align: center">
            <h2><i class="fas fa-warning"></i> RFID Karten Lernen Modus aktiv <i class="fas fa-warning"></i></h2>
            Es werden aktuell alle RFID Karten an den Ladepunkten akzeptiert und gespeichert.
        </mat-card>

        <app-meter-no-fleet-info></app-meter-no-fleet-info>

        <app-solar-system-no-fleet-info></app-solar-system-no-fleet-info>

	    <mat-card appearance="outlined" id="evse-table-container" class="p-0" *ngIf="(activeChargingSessions |async) as activeChargingSessions">
            <ng-container *ngIf="(basis | async) as basis">
                <app-evse-table
                    [evses]="basisOverview.list"
                    [vehicles]="vehicles"
                    [basis]="basis"
                    [activeChargingSessions]="activeChargingSessions"
                    [canCreate]="basisOverview.canAddEvse"
                ></app-evse-table>
            </ng-container>
	    </mat-card>

	    <div class="buttons d-flex flex-row justify-content-end gap-3">
	        <button [disabled]="!basisOverview.canAddEvse" mat-raised-button color="primary" [routerLink]="'/unknownCpis'">Geführte Einrichtung<span *ngIf="(unknownCpis | async) as unknownCpis"> ({{unknownCpis.length}})</span></button>
	        <button [disabled]="!basisOverview.canAddEvse" mat-raised-button color="primary" (click)="newEvse()">Neuer Ladepunkt</button>
	    </div>
    </ng-container>
</div>
<ng-template #loading>
    <div *ngIf="error">
        <h1>Hinweis</h1>
        {{error}}
    </div>
    <div *ngIf="!error">
        Loading...
    </div>
</ng-template>
